import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDjcBKxRwPaOzn9LahTXAm0QbftMrFZWBA',
  authDomain: 'codex-esdras.firebaseapp.com',
  projectId: 'codex-esdras',
  storageBucket: 'codex-esdras.appspot.com',
  messagingSenderId: '1011356456570',
  appId: '1:1011356456570:web:d8033735471bad20898dc3',
  measurementId: 'G-RC8LVC23XM',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
